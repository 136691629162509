import download from '../img/download.png';
import cv from '../downloads/OguzHoto_CV.pdf';
const Downloads = () => {
  return (
    <div className="LeftGroup">
      <h2>Downloads</h2>
      <div className="Box">
        <div className="Icon">
          <img src={download} alt="" />
        </div>
        <div className="Text">
          <span>Resume</span>
          <br />
          <a href={cv} download="OguzHotoCV">
            PDF
          </a>
        </div>
      </div>
    </div>
  );
};
export default Downloads;

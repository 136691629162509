import './App.css';
import Header from './components/Header';
import PersonalProfile from './components/PersonalProfile';
//LEFT
import Contact from './components/Contact';
import Education from './components/Education';
import Social from './components/Social';
import Downloads from './components/Downloads';
//RIGHT
import WorkExperience from './components/WorkExperience';
import Skills from './components/Skills';
import Interests from './components/Interests';

function App() {
  return (
    <div className="container">
      <Header />
      <PersonalProfile />
      <div className="page">
        <div className="left">
          <Contact />
          <Education />
          <Social />
          <Downloads />
        </div>
        <div className="right">
          <WorkExperience />
          <Skills />
          <Interests />
        </div>
      </div>
    </div>
  );
}

export default App;

import adress from '../img/map-marker.png';
import email from '../img/envelope.png';
import home from '../img/home.png';
const Contact = () => {
  return (
    <div className="LeftGroup">
      <h2>Contact</h2>
      <div className="Box">
        <div className="Icon">
          <img src={adress} alt="" />
        </div>
        <div className="Text">
          <span>Adress</span>
          <br /> Urla / İzmir / Turkey
        </div>
      </div>
      <div className="Box">
        <div className="Icon">
          <img src={email} alt="" />
        </div>
        <div className="Text">
          <span>E-Mail</span>
          <br /> oguzhoto@gmail.com
        </div>
      </div>
      <div className="Box">
        <div className="Icon">
          <img src={home} alt="" />
        </div>
        <div className="Text">
          <span>Web</span>
          <br /> oguzhoto.com
        </div>
      </div>
    </div>
  );
};
export default Contact;

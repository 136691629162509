import scuba from '../img/scuba-diving.png';
import swim from '../img/swimmer.png';
import astro from '../img/astrophysics.png';
import bike from '../img/bike.png';
import selfLearn from '../img/code.png';
import coffee from '../img/coffee.png';
import germanshepherd from '../img/german-shepherd.png';
const Interests = () => {
  return (
    <div className="WorkExperience interests">
      <div className="blackDot"></div>
      <h2>Interests and Hobby</h2>
      <div className="job">
        <div className="hollowDot"></div>
        <div className="hobbyContainer">
          <div className="hobby">
            <div className="logo">
              <img src={scuba} alt="" />
            </div>
            <div className="text">Scuba diving</div>
          </div>
          <div className="hobby">
            <div className="logo">
              <img src={swim} alt="" />
            </div>
            <div className="text">Swimming</div>
          </div>
          <div className="hobby">
            <div className="logo">
              <img src={bike} alt="" />
            </div>
            <div className="text">Cycling</div>
          </div>
          <div className="hobby">
            <div className="logo">
              <img src={astro} alt="" />
            </div>
            <div className="text">Astrophysics</div>
          </div>
          <div className="hobby">
            <div className="logo">
              <img src={selfLearn} alt="" />
            </div>
            <div className="text">Self Learning</div>
          </div>
          <div className="hobby">
            <div className="logo">
              <img src={coffee} alt="" />
            </div>
            <div className="text">Coffee</div>
          </div>
          <div className="hobby">
            <div className="logo">
              <img src={germanshepherd} alt="" />
            </div>
            <div className="text">German Shepherd</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Interests;

import img from '../img/img.jpg';
const PersonalProfile = () => {
  return (
    <div className="PersonalProfile">
      <div className="image">
        <img src={img} alt="Oğuz in METU Tennis Courts" />
      </div>
      <div className="text">
        <h2>Personal Profile</h2>
        <p>
          I have 8+ years of experience in geotechnical engineering with a good understanding and experience of tunnel design (NATM), slope stability analysis, deep & shallow foundation design, ground
          improvement design with deep soil mixing, jet grout and stone columns. I am highly motivated, team-oriented, goal-driven, enthusiastic, proactive, solution and detail-oriented. I am also a
          self-taught fullstack developer and I run&nbsp;
          <a href="https://www.geocalc.app/" target="_blank" rel="noreferrer">
            geocalc.app
          </a>
          &nbsp;where I develop calculation tools for geotechnical engineering community. In addition, I share my life with my cat, Fındık, who has asthma and my dog, Ateş, a rescued german shepherd
          from street.
        </p>
      </div>
    </div>
  );
};
export default PersonalProfile;

import linkedin from '../img/linkedin-letters.png';
import twitter from '../img/twitter-black-shape.png';
import instagram from '../img/instagram-symbol.png';
import github from '../img/github-logo.png';
import skype from '../img/skype-logo.png';
const Social = () => {
  return (
    <div className="LeftGroup">
      <h2>Social</h2>
      <div className="Box">
        <div className="Icon">
          <img src={linkedin} alt="" />
        </div>
        <div className="Text">
          <span>LinkedIn</span>
          <br />
          <a href="https://www.linkedin.com/in/oguzhoto/" target="_blank" rel="noreferrer">
            oguzhoto
          </a>
        </div>
      </div>
      <div className="Box">
        <div className="Icon">
          <img src={twitter} alt="" />
        </div>
        <div className="Text">
          <span>Twitter</span>
          <br />
          <a href="https://twitter.com/OguzHoto" target="_blank" rel="noreferrer">
            oguzhoto
          </a>
        </div>
      </div>
      <div className="Box">
        <div className="Icon">
          <img src={instagram} alt="" />
        </div>
        <div className="Text">
          <span>Instagram</span>
          <br />
          <a href="https://www.instagram.com/oguzhoto/" target="_blank" rel="noreferrer">
            oguzhoto
          </a>
        </div>
      </div>
      <div className="Box">
        <div className="Icon">
          <img src={github} alt="" />
        </div>
        <div className="Text">
          <span>Github</span>
          <br />
          <a href="https://github.com/oguzhoto" target="_blank" rel="noreferrer">
            oguzhoto
          </a>
        </div>
      </div>
      <div className="Box">
        <div className="Icon">
          <img src={skype} alt="" />
        </div>
        <div className="Text">
          <span>Skype</span>
          <br /> oguzhoto
        </div>
      </div>
    </div>
  );
};
export default Social;

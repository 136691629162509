const WorkExperience = () => {
  return (
    <div className="WorkExperience">
      <div className="blackDot"></div>
      <h2>Work Experiences</h2>
      <div className="job">
        <div className="hollowDot"></div>
        <h3>
          Founder & Fullstack Developer & Geotechnical Engineer @
          <a href="https://www.geocalc.app/" target="_blank" rel="noreferrer">
            geocalc.app
          </a>
          <br />
          (Mar. 2021 – Present)
        </h3>
        <p>- Designing and developing online calculation tools for geotechnical engineering community.</p>
        <p>- Developing front-end using HTML5, CSS3 and React. </p>
        <p>- Developing back-end using NodeJs, Express and MongoDB </p>
      </div>
      <div className="job">
        <div className="hollowDot"></div>
        <h3>
          Geotechnical Engineer @
          <a href="http://www.erkaas.com.tr/index.php/en/" target="_blank" rel="noreferrer">
            ERKAAS Engineering and Consulting Co. Ltd.
          </a>
          <br />
          (Sep. 2013 – Sep. 2021)
        </h3>
        <p>- Designed shallow and deep foundations for highway bridges and retaining structures.</p>
        <p>- Analysed slope stability of cuts and embankments for highways and designed necessary support systems.</p>
        <p>- Designed tunnel support systems, drainage, and construction steps according to NATM.</p>
        <p>- Decided tunnel entrance and exit locations and the support systems for cuts in these locations.</p>
        <p>- Designed ground improvement projects using deep soil mixing, jet grout and stone columns for cuts, embankments, bridge abutments & piers.</p>
        <p>- Analysed landslides using back-analysis, monitored inclinometer data, and designed necessary support systems for stabilization.</p>
        <p>- Performed engineering analysis and wrote geotechnical engineering reports summarizing data and recommendations developed during studies.</p>
        <p>- Negotiated technical solutions with Federal, State & local regulatory agencies.</p>
        <p>- Provided management & technical oversight for regulatory requirement compliance.</p>
        <p>- Designed and drafted boring location plans.</p>
        <p>- Drafted geotechnical engineering drawings in AutoCAD including soil profiles, boring location plans, etc. </p>
      </div>
      <div className="job">
        <div className="hollowDot"></div>
        <h3>
          Project Assistant @TUBITAK <br />
          (Sep. 2011 – Sep. 2013)
        </h3>
        <p>- Studied modelling of tsunami propagation and inundation in the Aegean Sea.</p>
        <p>- Developed software for editing bathymetric maps using MATLAB.</p>
      </div>
      <div className="job">
        <div className="hollowDot"></div>
        <h3>
          PADI Open Water Scuba Instructor, Id: 648750 @
          <a href="http://www.ermandive.com/html/eng/index.htm" target="_blank" rel="noreferrer">
            Erman Dive Center
          </a>
          <br />
          (Jun. 2008 – Sep. 2011)
        </h3>
        <p>- Trained open water scuba divers, advanced open scuba divers, rescue divers.</p>
      </div>
    </div>
  );
};
export default WorkExperience;

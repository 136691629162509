const Skills = () => {
  return (
    <div className="WorkExperience">
      <div className="blackDot"></div>
      <h2>Skills</h2>
      <div className="job">
        <div className="hollowDot"></div>- Midas GTX NX, Phase<sup>2</sup>, Slide, AutoCAD, MS Office
      </div>
      <div className="job">
        <div className="hollowDot"></div>- HTML5, CSS3, JavaScript ES5/ES6, React.js, Node.Js, Express, MongoDB, Mongoose, Git, GitHub
      </div>
    </div>
  );
};
export default Skills;

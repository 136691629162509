import graduate from '../img/graduation-cap.png';
const Education = () => {
  return (
    <div className="LeftGroup">
      <h2>Education</h2>
      <div className="Box">
        <div className="Icon">
          <img src={graduate} alt="" />
        </div>
        <div className="Text">
          <span>BSc</span>
          <br />{' '}
          <a href="https://www.metu.edu.tr/" target="_blank" rel="noreferrer">
            METU &nbsp;
          </a>
          -&nbsp;
          <a href="https://ce.metu.edu.tr/" target="_blank" rel="noreferrer">
            CE&nbsp;
          </a>
          | 2011 / Turkey
        </div>
      </div>
    </div>
  );
};
export default Education;
